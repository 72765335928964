<template>
  <div>
    <v-app-bar class="text-center" :elevation="1">
      <v-app-bar-title>SUUQ</v-app-bar-title>
    </v-app-bar>
    <div class="d-flex align-center justify-center" style="height: 90vh">
      <v-card elevation="0" width="400" class="mx-auto mt-n3">
        <v-card-title class="text-center mb-5">
          Recieved your verification code?
          <div class="mt-3 text-caption">
            <span class="text-grey"> Enter your verification code </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form class="ma-3">
            <div class="ma-auto position-relative mt-n5" style="max-width: 300px">
              <v-otp-input
                v-model="editedItem.otp"
                @finish="save"
              ></v-otp-input>
            </div>
            <v-btn
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              elevation="0"
              class="mt-3"
              @click="save"
            >
              Submit
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      otp: "",
      loading: false,
      editedItem: {
        otp: "",
        email: "",
      },
    };
  },
  computed: {
    passwordResetEmail() {
      return localStorage.getItem("password_reset_email");
    },
  },

  methods: {
    save() {
      this.loading = true;
      this.editedItem.email = this.passwordResetEmail;
      console.log("itemmsss", this.editedItem);
      AuthService.verifyOtp(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.otp = response.data.data;

            this.$router.push(this.$route.query.redirect || "/reset-password");
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.$router.push(
              this.$route.query.redirect || "/forgotten-password"
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
            this.$router.push(
              this.$route.query.redirect || "/forgotten-password"
            );
          }
          if (error.response.status == 401) {
            this.$store.dispatch("alert/error", error.response.message);
          }
          this.$store.dispatch("alert/error", error.response.data.message);
          this.$router.push(
            this.$route.query.redirect || "/forgotten-password"
          );
        }
      );
    },
  },
};
</script>