// Data service
export default function fileHeader(resource = null) {
    let token = localStorage.getItem('token');
    const headers = {
        'Accept': 'application/json',
    };

    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    if (!(resource instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
    }

    return headers;
}
