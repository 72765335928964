<template>
<div>
  <v-row no-gutter class="ma-1">
    <v-col cols="12" md="12" class="d-flex text-justify justify-center">
      <v-card width="820" variant="flat" rounded="sm" class="pa-1">
        <template v-slot:title>
          <div class="text-blue font-weight-bold">Platform Terms of Use</div>
          <div class="text-caption">Effective Date – 1 June 2024</div>
        </template>
        <template v-slot:text>
          <div>
            <p class="text-caption text-justify">{{ tandc.intro }}</p>
          </div>
          <div class="mt-3">
            <v-alert
              v-for="(term, index) in tandc.terms"
              :key="index"
              icon="mdi-square-small"
              variant=""
              class="my-n4"
            >
              <span class="text-blue font-weight-bold">{{
                term.title + ":"
              }}</span
              ><span class="text-caption text-justify">{{
                " " + term.descritpion
              }}</span>
              <div v-if="term.list">
                <v-alert
                  v-for="(list, index) in term.list"
                  :key="index"
                  icon="mdi-square-small"
                  variant=""
                  class="my-n4"
                >
                  <span v-if="list.title" class="text-blue font-weight-bold">{{
                    list.title + ":"
                  }}</span>
                  <span class="text-caption text-justify">{{
                    " " + list.descritpion
                  }}</span>
                </v-alert>
              </div>
            </v-alert>
          </div>
        </template>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="mb-n5">
    <v-col cols="12">
      <FooterComponent></FooterComponent>
    </v-col>
  </v-row>
</div>
</template>

<script>
import FooterComponent from '@/components/footer/FooterComponent.vue'
export default {
  components: {
    FooterComponent,
  },
  data: () => ({
    tandc: {
      intro: `These SUUQ Platform Terms of Use (these "Terms of Use") tell you the rules for using the platform available at www.suuq.so and through any SUUQ mobile application available from time to time (collectively, the "Platform"), as well as any information, content or materials published on, or available via, the Platform (collectively, the "Content").
These Terms of Use govern and condition access to, and use of, the Platform and its Content by each visitor to this Platform ("you").
`,
      terms: [
        {
          title: "WHO WE ARE AND HOW TO CONTACT US.",
          descritpion: `The Platform is operated and owned by SUUQ MIDDLE EAST FZ-LLC ("SUUQ", "we", "our", "us").
Our mailing address is at 6TH FLOOR, PARKPLACE, NAIROBI, KENYA.
To contact us about these Terms of Use or anything else relating to your use of this Platform, please email support@SUUQ.com.
`,
        },
        {
          title: "BY USING THE PLATFORM, YOU ACCEPT THESE TERMS OF USE",
          descritpion: `By accessing and continuing to use the Platform, you confirm that you accept these Terms of Use, and acknowledge and agree that these Terms of Use govern and condition your access to, and use of, the Platform and any Content.
You understand that these Terms of Use constitute a legally binding agreement between you and SUUQ.
If you are using the Platform as a business entity or on behalf of a business entity, you represent that you have the authority to legally bind that entity.
If you do not agree to be bound by these Terms of Use, you may not access or use the Platform or any Content and should cease to do so immediately.
`,
        },
        {
          title: "WE MAY MAKE CHANGES TO THESE TERMS OF USE",
          descritpion: `We may amend these Terms of Use from time to time, with or without notice to you. The new version of these Terms of Use will be made available on this page. If you are an existing registered user, we may also notify you of any material update to these Terms of Use via email (for example, where we are legally required to do so).
Every time you wish to use the Platform, please check these Terms of Use to ensure that you understand the terms and conditions that apply to the Platform and its Content at that time.
`,
        },
        {
          title: "WE MAY MAKE CHANGES TO THE PLATFORM",
          descritpion: `We may change the way the Platform operates and/or change any Content from time to time without notice to you. This could be, for example, to reflect changes to the features and functionalities of the Platform, the state of current technology, or market practice, applicable laws or regulations and/or our business priorities.`,
        },
        {
          title: "WE MAY SUSPEND OR WITHDRAW THE PLATFORM",
          descritpion: `We do not guarantee that the Platform and/or any Content will always be available free of charge, or generally available. We may suspend or withdraw, or restrict the availability of, all or any part of the Platform for business and/or operational reasons.`,
        },
        {
          title: "YOUR PRIVACY",
          descritpion: `Please refer to our Privacy Policy to understand how we collect, process and share your personal data in relation to your use of the Platform.`,
        },
        {
          title: "YOUR RIGHT TO USE THE PLATFORM AND THE CONTENT.",
          descritpion: `Subject to your continued compliance with these Terms of Use, SUUQ grants you a personal, limited, non-transferable, non-exclusive, revocable right to use the Platform and the Content.
You may print off one (1) copy, and may download extracts, of any page(s) from the Platform for your personal use and you may draw the attention of others to Content available on the Platform. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. You must not use any part of the Content for commercial purposes without obtaining a licence to do so from us.
SUUQ is the owner or the licensee of all rights (including intellectual property rights) in the Platform and any Content, including without limitation any designs, text, graphics and the selection and arrangement thereof. The Platform and the Content are protected by copyright laws and treaties around the world. All such rights are reserved.
All trademarks, logos, trade dress, service names and service marks ("Marks") displayed on the Platform are our property or the property of certain other third-parties. You are not permitted to use these Marks without our prior written consent.
`,
        },
        {
          title: "RESTRICTIONS",
          descritpion: `We will report any breach of the restrictions listed below to the relevant law enforcement authorities and will cooperate with those authorities by disclosing your identity to them.`,
          list: [
            {
              title: "",
              descritpion: `You must not use the Platform if you are under the age of eighteen (18)`,
            },
            {
              title: "",
              descritpion: `You must not use the Platform in any unlawful manner or for any unlawful purpose`,
            },
            {
              title: "",
              descritpion: `You must not attempt to gain unauthorised access to the Platform, the server on which the Platform is stored or any server, computer or database connected to the Platform`,
            },
            {
              title: "",
              descritpion: `Do not interfere with, damage or disrupt any part of the Platform or any Content, or any equipment or network on which the Platform or any Content is stored, or any software or other systems or equipment used in the provision of the Platform or any Content`,
            },
            {
              title: "",
              descritpion: `You must not knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful to the Platform)`,
            },
            {
              title: "",
              descritpion: `Do not impersonate any other person while using the Platform`,
            },
            {
              title: "",
              descritpion: `Do not conduct yourself in a vulgar, offensive, harassing or objectionable manner while using the Platform;`,
            },
            {
              title: "",
              descritpion: `Do not use the Platform to generate unsolicited advertisements or spam`,
            },
            {
              title: "",
              descritpion: `You must not reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purpose all or any portion of the Platform or any Content`,
            },
            {
              title: "",
              descritpion: `Do not use manual methods or any software, devices, scripts, robots or any other means or processes (including so-called ‘spiders’, ‘bots’ and ‘crawlers’) to scrape the Platform and/or its Content, or otherwise create or compile (in single or multiple downloads) a collection, compilation, database or directory from the Platform or its Content, or bypass or seek to bypass any robot exclusion headers we may implement; or`,
            },
            {
              title: "",
              descritpion: `reverse-engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Platform and/or its Content.`,
            },
          ],
        },

        {
          title: "PAID SERVICES",
          descritpion: `SUUQ may, from time to time, offer paid services on the Platform ("Paid Service"). For example, you may choose to pay a fee to ensure that your Listing is featured more prominently in search results on the Platform.
You acknowledge and agree that, in respect of each Paid Service:
`,
          list: [
            {
              title: "",
              descritpion: `any fee paid by you for a Paid Service is non-refundable in all circumstances; and`,
            },
            {
              title: "",
              descritpion: `Paid Services are provided for your convenience, and do not guarantee that your Listing will be successful or result in any minimum number of leads for your Listing.`,
            },
          ],
        },
        {
          title: "LISTINGS",
          descritpion: `The Platform enables a user wishing to sell an item or service (the "Seller") to advertise such item or service (each a "Listing") to other users of the Platform. A user who is interested in such item or service (the "Buyer") may then contact the Seller (via the Listing) to make an offer for such item or service.
If you are a Seller, you must:`,
          list: [
            {
              descritpion: `be legally permitted to sell the item or service on the Platform`,
            },
            {
              descritpion: `be the legal owner of the item, or be legally permitted to sell the item or provide the service, advertised on your Listing`,
            },
            {
              descritpion: `ensure that your Listing complies with our Acceptable Use Policy`,
            },
            {
              descritpion: `include a complete and accurate descritpion of the item or service on your Listing`,
            },
            {
              descritpion: `only include one (1) item or service on any Listing, and you may not sell multiple items or services through a single Listing`,
            },
            {
              descritpion: `not post Listings in inappropriate categories or areas on the Platform`,
            },
            {
              descritpion: `not post Listings for any counterfeit items, or items that otherwise infringe the copyright, trademark or other rights of third-parties`,
            },
            {
              descritpion: `not use the platform as a third-party agent, service or intermediary that offers to post Listings on the Platform on behalf of others`,
            },
            {
              descritpion: `comply with all applicable laws and regulations in connection with your Listing; and`,
            },
            {
              descritpion: `act lawfully and in good faith in your dealings with Buyers`,
            },
            {
              descritpion: `If you are a Buyer, you must: be legally permitted to purchase the item or service through the Platform and act lawfully and in good faith in your dealings with any Seller.`,
            },
          ],
        },
        {
          title: "SUUQ IS NOT RESPONSIBLE FOR LISTINGS",
          descritpion: `You acknowledge and agree that SUUQ does not have an obligation to monitor, approve or moderate Listings or their content. We are not responsible for the Listings posted on the Platform.
If you wish to purchase an item or service via the Platform, you are purchasing it from the user who posted the relevant Listing, without SUUQ’s involvement.
Although SUUQ is not responsible for Listings, we reserve the right to monitor Listings and to remove any Listing from the Platform if, in our sole opinion, a Listing is in breach of these Terms of Use (including the Acceptable Use Policy).
`,
        },
        {
          title: "UPLOADING CONTENT TO THE PLATFORM",
          descritpion: `Any content you upload to the Platform, including as part of a Listing, will be considered non-confidential and non-proprietary.
You retain all of your ownership rights in your content, but you are required to grant us and other users of the Platform a limited licence to use, store and copy that content and to distribute and make it available to third-parties. More specifically, you grant us a perpetual, worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display and perform that content in connection with the service provided on the Platform and to promote the Platform.
You are solely responsible for securing and backing up your content.
We have the right to disclose your identity to any third-party who claims that any content posted or uploaded by you to the Platform constitutes a violation of their intellectual property rights or of their right to privacy.
We reserve the right to remove any content posted or uploaded by you to the Platform with or without notice if, in our sole opinion, it does not comply with these Terms of Use (including the Acceptable Use Policy).
`,
        },
        {
          title: "ACCEPTABLE USE POLICY",
          descritpion: `Whenever you make use of a feature that allows you to upload content to the Platform, or to make contact with other users of the Platform, you must comply with the standards set out in our Acceptable Use Policy.
You warrant that any such content does comply with those standards, and you are liable to us and indemnify us for any breach of that warranty. This means that you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
15.	PROCESSING PAYMENTS.
We may, in certain circumstances, collect payments from you via the Platform (including when you choose to purchase Paid Services).
If that is the case, by providing us details of your payment method at checkout, you authorise us (acting through our chosen payment processor) to charge the relevant payment method for the amount displayed to you at checkout.
We will not be responsible for any losses you may suffer if the payment method you use to make a payment on the Platform does not have sufficient funds to cover the full value of such payment.
`,
        },
        {
          title: "PROCESSING PAYMENTS",
          descritpion: `We may, in certain circumstances, collect payments from you via the Platform (including when you choose to purchase Paid Services).
If that is the case, by providing us details of your payment method at checkout, you authorise us (acting through our chosen payment processor) to charge the relevant payment method for the amount displayed to you at checkout.
We will not be responsible for any losses you may suffer if the payment method you use to make a payment on the Platform does not have sufficient funds to cover the full value of such payment.
`,
        },
        {
          title: "OUR LIABILITY TO YOU",
          descritpion: `We do not intend to exclude or limit in any way our liability to you where it would be unlawful to do so.
Subject to the above, our liability to you is limited as list below. In no event will SUUQ’s liability to you in connection with your use of the Platform or these Terms of Use, regardless of the cause of action or loss suffered by you, exceed the higher of: (i) the amount paid by you for the Paid Service which is the subject of the relevant dispute or claim (if any); or (ii) one thousand USD Dollars (USD 1,000).`,
          list: [
            {
              title: "",
              descritpion: `If you are using the Platform in a business or commercial capacity: We provide the Platform and the Content to you ‘as is’ and ‘as available’. We exclude all implied conditions, warranties, representations or other terms that may apply to the Platform or any Content. We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty or otherwise, even if foreseeable, arising under or in connection with your use of, or inability to use, the Platform, or your use of or reliance on any Content. In particular, we will not be liable to you for any: (i) loss of profits, sales, business or revenue; (ii) business interruption; (iii) loss of anticipated savings; (iv) loss of business opportunity, goodwill or reputation; (v) loss or corruption of data; or (vi) indirect or consequential loss or damage.`,
            },
            {
              title: "",
              descritpion: `If you are using the Platform in a personal capacity: You acknowledge and agree that we only provide the Platform to you for domestic and private use. We do not guarantee the availability of the Platform, or that the Platform or any Content will be error-free or fit for any specific purpose. You agree not to use the Platform for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption or loss of business opportunity. If we fail to comply with these Terms of Use, we are only responsible for loss or damage you suffer that is a foreseeable result of our breaching these Terms of Use or failing to act with reasonable care and skill.`,
            },
          ],
        },
        {
          title: "BREACH OF THESE TERMS OF USE",
          descritpion: `When we consider, in our sole opinion, that you are in breach of any part of these Terms of Use, we may take such action as we deem appropriate in our sole discretion, including without limitation suspending or withdrawing your right to use the Platform or its Content, closing your Account and/or taking legal action against you.`,
        },
        {
          title: "REPORTING ILLEGAL OR INFRINGING CONTENT",
          descritpion: `If you come across any Content on the Platform that you believe to be illegal, please contact us immediately at support@SUUQ.com with full details of the illegal Content.
If you are the owner of intellectual property rights, or an agent who is fully authorised to act on behalf of the owner of intellectual property rights, and believe that any Content infringes upon your intellectual property right or the intellectual property rights of the owner on whose behalf you are authorised to act, please notify SUUQ at support@SUUQ.com with full details of the alleged infringement. We will use all reasonable efforts to remove any infringing Content from the Platform within a reasonable period of time.
`,
        },

        {
          title: "GENERAL",
          descritpion: `Links to third-party sites or content. Where the Platform contains links to other websites and/or third-party content (including in Listings), these links are provided for your information and convenience only. Such links should not be interpreted as approval by us of those linked websites or any content you may obtain from them. We have no control over the contents of such third-party websites, and exclude all liability in that respect.
Rules about linking to the Platform. You may link to any page of the Platform or any specific Content, provided that you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. The Platform must not be framed on any other website. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. We reserve the right to withdraw linking permission without notice.
Contact. You may contact us by emailing support@SUUQ.com. If we need to contact you, we will write to the email address associated with your Account (if any).
We are not responsible for viruses. We do not guarantee that the Platform and the Content will be secure or free from bugs or viruses. You are responsible for configuring your device to access the Platform and the Content in a secure way. Where relevant, you should use your own virus protection software.
We are not liable for events outside our control. We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations that is caused by events outside our reasonable control (each a "Force Majeure Event"). A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control. The performance of our obligations under these Terms of Use is deemed to be suspended for the period that the Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use reasonable efforts to bring the Force Majeure Event to a close or to find a solution by which our obligations under these Terms of Use may be performed despite the Force Majeure Event.
Other agreements between you and SUUQ. You may have entered into other agreements with SUUQ or its affiliates, or may have accepted other terms and conditions governing the use of other services provided by SUUQ or its affiliates. These Terms of Use apply in addition to any such agreements. In the event of any conflict or ambiguity between these Terms and any other agreement between you and SUUQ or its affiliate, the provisions of these Terms will prevail (but only to the extent of such conflict or ambiguity).
We may transfer our rights and obligations. We may transfer our rights and obligations under these Terms of Use to another organisation. We will notify you in writing if this happens, and we will ensure that the transfer will not affect your rights under these Terms of Use.
Nobody else has any rights under these Terms of Use. These Terms of Use are between you and SUUQ only, and no other person will have any rights to enforce or rely on any of its provisions.
Even if we delay enforcing our rights under these Terms of Use, we can still enforce them later. If we do not insist immediately that you do anything you are required to do under these Terms of Use, or if we delay taking steps against you in respect of your breaching these Terms of Use, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
If a court finds part of these Terms of Use illegal, the rest will continue in force. Each of the sections of these Terms of Use operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining sections will remain in full force and effect.
Accessing the Platform from another territory. The Platform is directed to people residing in certain territories only. We cannot ensure that the Content available on or through the Platform is appropriate for use or available in locations we do not explicitly make the Platform available in, and cannot ensure that the Platform complies with the laws and regulations in territories we do not operate in.
Language. If these Terms of Use are translated into any other language and there is a discrepancy between the English text and the text in the other language, the English text version will prevail to the fullest extent permitted by applicable law.
Dispute resolution. If a dispute arises between you and SUUQ, we strongly encourage you to first contact us directly to seek a resolution by emailing support@SUUQ.com. We will review your complaint and do our best to address it. If a dispute between us cannot be resolved amicable, then to the fullest extent permitted by applicable law, these Terms of Use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by the laws of the Dubai International Financial Centre ("DIFC") and will be interpreted accordingly. You irrevocably agree that the DIFC courts will have exclusive jurisdiction to settle any dispute or claim arising out of, or in connection with, your use of the Platform or these Terms of Use, and all matters arising from them (including any dispute relating to the existence, validity or termination of these Terms of Use, or any contractual or non-contractual obligation).
`,
        },

        {
          title: "ADDITIONAL TERMS",
          descritpion: `The Annexes to these Terms of Use contain additional terms and conditions applicable to specific features of the Platform. These additional terms and conditions apply in addition to, but do not replace, the main part of these Terms of Use. If there is any conflict or ambiguity between the main part of these Terms of Use and any Annex, the provisions of the main part of these Terms of Use will take precedence.
Property for Sale and Property for Rent. If you wish to use the ‘Property for Sale’ and ‘Property for Rent’ sections of the Platform, please refer to the additional terms set out in Annex 1.
Motors. If you wish to use the ‘Motors’ section of the Platform, please refer to the additional terms set out in Annex 2.
Jobs. If you wish to use the ‘Jobs’ section of the Platform, please refer to the additional terms set out in Annex 3.
Vehicle Inspection Terms and Conditions
Vehicle inspection terms and conditions can be found here:T&Cs for Vehicle Inspection
Vehicle Auction Terms & Conditions
Vehicle Auction Terms and Conditions can be found here:Vehicle Auction Terms and Conditions
`,
        },
        {
          title: "Acceptable Use Policy",
          descritpion: `This Acceptable Use Policy outlines the terms and conditions for using the different sections of the SUUQ Platform, covering property, motors, and jobs.`,
        },
        {
            title: "Annex 1: Additional Terms - Properties",
            descritpion:"",
          list: [
            {
              title:"Property for Sale and Property for Rent",
              descritpion: `Terms for property owners, brokers, real estate agents, developers, buyers, and renters.`,
            },
            {
              title: "For Property Owners",
              descritpion: `You must be the registered owner or landlord of the property. The property must be currently available for sale or lease and comply with legal restrictions.`,
            },
            {
              title: "For Brokers/Real Estate Agents/Developers",
              descritpion: `You need a valid license from the relevant real estate authority and appropriate commercial licenses. Off-plan properties must be registered and authorized by the relevant authority. Properties must be available and legally permissible for sale or lease.`,
            },

            {
              title: "For Buyers/Renters",
              descritpion: `SUUQ only facilitates the advertising of properties and is not a real estate broker. Conduct your own due diligence as SUUQ does not verify the accuracy of property listings.`,
            },
          ],
          
        },

        {
          title: "Annex 2: Additional Terms – Motors",
          descritpion: `Terms for sellers of vehicles.`,
          list: [
            {
              title: "For Sellers",
              descritpion: `The vehicle must be roadworthy, legally owned by you or the person on whose behalf you are listing it, located in your country, and accurately described in your listing. SUUQ may request proof of ownership, location, or customs clearance, and failure to provide this within the specified timeframe may result in the removal of your listing without a refund.`,
            },
          ],
        },

        {
          title: "Annex 3: Additional Terms – Jobs",
          descritpion: `Terms for employers, recruiters, and job seekers.`,
          list: [
            {
              title: "For Employers/Recruiters",
              descritpion: `SUUQ is not responsible for the accuracy of job seekers’ information and does not guarantee the suitability of candidates. Fees paid are non-refundable.`,
            },
            {
              title: "For Job Seekers",
              descritpion: `By placing a job-seeking ad, employers and recruiters will access your personal details and CV. Fees paid are non-refundable.`,
            },
          ],
        },
      ],
    },
  }),
};
</script>