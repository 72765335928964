<template>
  <div>
    <v-card class="mx-auto mt-3" elevation="0" max-width="600">
      <v-card-title class="text-center">
        <div class="text-center font-weight-bold text-error text-h6 mt-n3">
          Create your Motors Ad
        </div>
      </v-card-title>

      <v-window v-model="step">
        <!-- Step 1: Subcategory, Group, and Images -->
        <v-window-item :value="1">
          <v-card-title class="text-center font-weight-bold text-caption">
            Select Subcategory and add Images
          </v-card-title>
          <v-card-text class="mt-2">
            <v-text-field
              v-model="editedItem.title"
              label="Title"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Title is required']"
            ></v-text-field>
            <v-autocomplete
              v-model="editedItem.subCategoryId"
              :items="subCategories"
              readonly
              item-value="id"
              item-text="title"
              label="Select Subcategory"
              variant="outlined"
              :rules="[(v) => !!v || 'Subcategory is required']"
            ></v-autocomplete>

            <v-autocomplete
              v-model="editedItem.groupId"
              :items="filteredGroups"
              item-value="id"
              readonly
              item-text="title"
              label="Select Group"
              variant="outlined"
              :rules="[(v) => !!v || 'Group is required']"
            ></v-autocomplete>

            <v-textarea
              v-model="editedItem.description"
              label="Describe your item"
              readonly
              class="mt-2"
              variant="outlined"
              :rules="[(v) => !!v || 'Description is required']"
            ></v-textarea>
          </v-card-text>
        </v-window-item>

        <!-- step 2: images  -->
        <v-window-item :value="2">
          <v-card-title class="text-center font-weight-bold text-caption">
            Property Images
          </v-card-title>
          <v-card elvation="0" class="mb-10">
            <v-carousel
              height="400"
              show-arrows="hover"
              cycle
              hide-delimiter-background
            >
              <v-carousel-item v-for="(file, i) in editedItem.images" :key="i">
                <v-sheet height="100%">
                  <v-img
                    class="bg-grey-lighten-2 mt-4"
                    height="500"
                    :src="`data:${file.mime_type};base64,${file.base64_data}`"
                    cover
                  ></v-img>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-window-item>

        <!-- Step 3: Basic Car Details -->
        <v-window-item :value="3" class="mt-n2">
          <v-card-title class="text-center font-weight-bold text-caption">
            Details
          </v-card-title>
          <v-card-text class="mt-2">
            <v-text-field
              v-model="editedItem.usage"
              label="Usage (e.g., Personal, Commercial)"
              variant="outlined"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="editedItem.makeModel"
              label="Make & Model"
              variant="outlined"
              readonly
              :rules="[(v) => !!v || 'Make & Model is required']"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.manufacture"
              label="Manufacture"
              readonly
              variant="outlined"
            ></v-text-field>

            <v-autocomplete
              v-model="editedItem.specs"
              :items="specsOptions"
              label="Regional Specifications"
              variant="outlined"
              readonly
              :rules="[(v) => !!v || 'Specifications are required']"
            ></v-autocomplete>

            <v-text-field
              v-model="editedItem.year"
              label="Year"
              variant="outlined"
              readonly
              type="number"
              :rules="[(v) => !!v || 'Year is required']"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.kilometers"
              label="Kilometers"
              variant="outlined"
              readonly
              type="number"
              :rules="[(v) => !!v || 'Kilometers are required']"
            ></v-text-field>

            <v-autocomplete
              v-model="editedItem.bodyType"
              :items="bodyTypes"
              label="Body Type"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Body type is required']"
            ></v-autocomplete>
          </v-card-text>
        </v-window-item>

        <!-- Step 4: Insurance and Price -->
        <v-window-item :value="4">
          <v-card-title class="text-center font-weight-bold text-caption">
            Insurance and Price
          </v-card-title>
          <v-card-text class="mt-2">
            <v-select
              v-model="editedItem.isInsured"
              :items="['Yes', 'No']"
              label="Is your item insured?"
              readonly
              variant="outlined"
            ></v-select>

            <v-text-field
              v-model="editedItem.price"
              label="Price"
              variant="outlined"
              readonly
              type="number"
              :rules="[(v) => !!v || 'Price is required']"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.phoneNumber"
              label="Phone Number"
              variant="outlined"
              :rules="[(v) => !!v || 'Phone number is required']"
            ></v-text-field>
          </v-card-text>
        </v-window-item>

        <!-- Step 5: Location -->
        <v-window-item :value="5">
          <v-card-title class="text-center font-weight-bold text-caption">
            Location
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="editedItem.cityId"
              :items="cities"
              item-value="id"
              readonly
              item-text="title"
              label="Select City"
              variant="outlined"
              :rules="[(v) => !!v || 'City is required']"
            ></v-autocomplete>

            <!-- Location Map -->
            <v-card class="mx-auto" elevation="0" max-width="600">
              <v-card-text class="bg-surface-light pt-4">
                <GoogleMap :cities="selectedCity"></GoogleMap>
              </v-card-text>
            </v-card>
          </v-card-text>

          <!-- step 6: Payment & EVC -->
          <v-window-item :value="6">
            <v-card-title class="text-center text-caption font-weight-bold"
              >Payment & Submit EVC Code</v-card-title
            >
            <v-card-text>
              <!-- editedItem.listingId -->
              <div class="text-center">
                <v-icon
                  color="error"
                  size="x-large"
                  icon="mdi-contactless-payment-circle"
                ></v-icon>
                <div class="mt-2 text-caption font-weight-bold">
                  <span class="">Payable Amount (USD):</span>
                  <span class="text-error font-weight-black">{{
                    " " + payableAmount
                  }}</span>
                </div>
              </div>
              <v-text-field
                v-model="editedItem.transactionReference"
                label="EVC Code"
                class="mt-6"
                variant="outlined"
                :rules="[(v) => !!v || 'EVC is required']"
              ></v-text-field>
            </v-card-text>
          </v-window-item>
        </v-window-item>
      </v-window>

      <!-- Navigation Buttons -->
      <v-card-actions>
        <v-icon
          v-if="step > 1"
          color="error"
          size="large"
          icon="mdi-chevron-left"
          @click="step--"
        ></v-icon>
        <v-spacer></v-spacer>
        <v-btn v-if="step < 5" color="error" variant="outlined" @click="step++">
          Next
        </v-btn>
        <v-btn
          v-if="step === 5"
          variant="outlined"
          color="blue"
          @click="step--"
        >
          Back
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import GroupsService from "../../services/group.services.js";
import SubcategoriesService from "../../services/sub.category.services.js";
import CitiesService from "../../services/cities.services.js";
import CategoryListingService from "../../services/category.listing.service.js";
import ListingCostService from "../../services/listing.cost.service.js";
import TransactionsService from "../../services/transaction.service.js";
import DraftService from "../../services/draft.services.js";
import GoogleMap from "@/components/GoogleMapComponent.vue";

export default {
  name: "MotorList",
  props: {
    Listing: {
      type: Object,
      required: true,
    },
  },
  components: {
    GoogleMap,
  },
  data: () => ({
    step: 1,
    subCategories: [],
    groups: [],
    cities: [],
    listingCosts: [],
    selectedCity: [],
    specsOptions: [
      "GCC Specs",
      "American Specs",
      "Canadian Specs",
      "European Specs",
      "Japanese Specs",
      "Korean Specs",
      "Chinese Specs",
      "Other",
    ],
    bodyTypes: [
      "SUV",
      "Coupe",
      "Sedan",
      "Crossover",
      "Hard Top Convertible",
      "Pick Up Truck",
      "Hatchback",
      "Soft Top Convertible",
      "Sports Car",
      "Van",
      "Wagon",
      "Utility Truck",
      "Other",
    ],
    locationCoordinates: "24.4539,54.3773", // Example coordinates
    editedItem: {
      subCategoryId: "",
      title: "",
      groupId: "",
      images: [],
      makeModel: "",
      trim: "",
      specs: "",
      year: "",
      kilometers: "",
      bodyType: "",
      isInsured: "",
      price: "",
      phoneNumber: "",
      cityId: "",
      userId: "",
    },

    defaultItem: {
      subCategoryId: "",
      title: "",
      groupId: "",
      images: [],
      makeModel: "",
      trim: "",
      specs: "",
      year: "",
      kilometers: "",
      bodyType: "",
      isInsured: "",
      price: "",
      phoneNumber: "",
      cityId: "",
      userId: "",
    },
  }),



  computed: {
    selectedCategory() {
      return this.$store.getters["category/getCategory"];
    },

    userDraft() {
      return this.$store.getters["category/getDraftedListing"];
    },

    payableAmount() {
      console.log("the cat", this.listingCosts);
      const cost = this.listingCosts.find(
        (cost) => cost.categoryId == this.selectedCategory._id
      );
      return cost ? cost.amount : 0;
    },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    user() {
      return this.loggedInUser || {};
    },

    filteredGroups() {
      if (this.editedItem.subCategoryId) {
        return this.groups.filter(
          (group) => group.subCategoryId === this.editedItem.subCategoryId
        );
      } else {
        return this.groups;
      }
    },
  },

  created() {
    this.getGroups();
    this.getSubcategories();
    this.getCities();
    this.getlistingCosts();
    this.setDraftData();

    this.editedItem = this.Listing ? { ...this.Listing.activeRelation } : {};
    this.editedItem.title = this.Listing.title;
    this.editedItem.images = this.Listing.files;
    this.editedItem.condition = this.Listing.condition;
    this.editedItem.manufacture = this.Listing.manufacture;
    this.editedItem.description = this.Listing.description;
    this.selectedCity = Array.isArray(this.Listing.city) ? this.Listing.city : [this.Listing.city];
  },

  methods: {
    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getGroups() {
      return GroupsService.index().then(
        (response) => {
          if (response.status == 200) {
            this.groups = response.data.data;
          } else {
            this.groups = [];
          }
        },
        (error) => {
          this.groups = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    setDraftData() {
      if (this.userDraft) {
        this.editedItem = { ...this.userDraft };
        this.editedItem.draftId = this.userDraft.id;
        this.editedItem.pricePerYear = this.userDraft.price;
        this.editedItem.userId = this.userDraft.user._id;
        this.editedItem.location = this.userDraft.cityId;
        this.editedItem.categoryId = this.defaultItem.categoryId;
        this.editedItem.groupIdId = this.defaultItem.groupId;
      } else {
        this.editedItem = this.defaultItem;
      }
      console.log("set draft", this.userDraft);
    },

    getlistingCosts() {
      return ListingCostService.index().then(
        (response) => {
          if (response.status == 200) {
            this.listingCosts = response.data.data;
            this.loading = false;
          } else {
            this.listingCosts = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listingCosts = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getSubcategories() {
      return SubcategoriesService.getBycategory(this.selectedCategory._id).then(
        (response) => {
          if (response.status == 200) {
            this.subCategories = response.data.data;
          } else {
            this.subCategories = [];
          }
        },
        (error) => {
          this.subCategories = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },
    getCities() {
      return CitiesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data;
          } else {
            this.cities = [];
          }
        },
        (error) => {
          this.cities = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    saveDraft() {
      this.editedItem.userId = this.user._id;
      this.editedItem.categoryId = this.selectedCategory._id;
      DraftService.store(this.editedItem)
        .then((response) => {
          if (response.status === 201) {
            this.$store.dispatch("alert/success", response.data.message);
            // console.log("Item created:", response.data);
          } else {
            this.$store.dispatch(
              "alert/error",
              "Failed to save item in draft."
            );
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error.message);
          console.log("Creation failed:", error);
        });
    },

    save() {
      this.editedItem.userId = this.user._id;
      this.editedItem.categoryId = this.selectedCategory._id;
      if (this.editedItem.id) {
        // Update existing item
        CategoryListingService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            if (response.status === 200) {
              this.$store.dispatch("alert/success", response.data.message);
              // this.$store.dispatch("category/clearDraft");
              console.log("Item updated:", response.data);
            } else {
              this.$store.dispatch("alert/error", "Failed to update the item.");
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error.message);
            console.log("Update failed:", error);
          });
      } else {
        // Create new item
        CategoryListingService.store(this.editedItem)
          .then((response) => {
            if (response.status === 201) {
              this.$store.dispatch("alert/success", response.data.message);
              this.$store.dispatch("category/clearDraft");
              this.step = 5;
              console.log("Item created:", response.data);
            } else {
              this.$store.dispatch("alert/error", "Failed to create the item.");
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error.message);
            console.log("Creation failed:", error);
          });
      }
    },

    submit() {
      this.editedItem.userId = this.user._id;
      this.editedItem.categoryId = this.selectedCategory._id;
      this.editedItem.amount = this.payableAmount;
      console.log("what i see", this.editedItem);
      TransactionsService.store(this.editedItem)
        .then((response) => {
          if (response.status === 201) {
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/");
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error.message);
          console.log("Creation failed:", error);
        });
    },
  },
};
</script>

<style scoped></style>
