const state = {
    selectedAttributes: null,
  };
  
  const mutations = {
    SET_SELECTED_ATTRIBUTES(state, creteria) {
      state.selectedAttributes = creteria;
    },
  };
  
  const actions = {
    setSelectedAttributes({ commit }, creteria) {
      commit('SET_SELECTED_ATTRIBUTES', creteria);
    },
  };
  
  const getters = {
    getSelectedAttributes: (state) => state.selectedAttributes,
  };
  
  export const filter = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  