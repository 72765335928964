<template>
  <v-container>
    <v-app-bar color="grey-lighten-4" prominent>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn color="error" class="error text-caption mr-10" variant="flat">Guest Dashboard</v-btn>
    </v-app-bar>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>