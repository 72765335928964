<template>
  <div>
    <v-card class="mx-auto mt-3" elevation="0" max-width="600">
      <v-card-title class="text-center">
        <div class="text-center font-weight-bold text-error text-h6 mt-n3">
          Create your Job Listing
        </div>
      </v-card-title>

      <v-window v-model="step">
        <!-- Step 1: Subcategory, Group, and Location -->
        <v-window-item :value="1">
          <v-card-title class="text-center font-weight-bold text-caption">
            Select Subcategory and Group
          </v-card-title>
          <v-card-text class="mt-2">
            <v-text-field
              v-model="editedItem.title"
              readonly
              label="Job title"
              variant="outlined"
              :rules="[(v) => !!v || 'Title is required']"
            ></v-text-field>
            <v-autocomplete
              v-model="editedItem.subCategoryId"
              :items="subCategories"
              readonly
              item-value="id"
              item-text="title"
              label="Select Subcategory"
              variant="outlined"
              :rules="[(v) => !!v || 'Subcategory is required']"
            ></v-autocomplete>

            <v-autocomplete
              v-model="editedItem.groupId"
              :items="filteredGroups"
              readonly
              item-value="id"
              item-text="title"
              label="Select Group"
              variant="outlined"
              :rules="[(v) => !!v || 'Group is required']"
            ></v-autocomplete>

            <v-textarea
              v-model="editedItem.cvSummary"
              label="CV Summary"
              variant="outlined"
              :rules="[(v) => !!v || 'CV Summary is required']"
              maxlength="2500"
              readonly
              counter
            ></v-textarea>
          </v-card-text>
        </v-window-item>

        <!-- step 2: images  -->
        <v-window-item :value="2">
          <v-card-title class="text-center font-weight-bold text-caption">
            Property Images
          </v-card-title>
          <v-card elvation="0" class="mb-10">
            <v-carousel
              height="400"
              show-arrows="hover"
              cycle
              hide-delimiter-background
            >
              <v-carousel-item v-for="(file, i) in editedItem.images" :key="i">
                <v-sheet height="100%">
                  <v-img
                    class="bg-grey-lighten-2 mt-4"
                    height="500"
                    :src="`data:${file.mime_type};base64,${file.base64_data}`"
                    cover
                  ></v-img>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-window-item>

        <!-- Step 3: Job Details -->
        <v-window-item :value="3">
          <v-card-title class="text-center font-weight-bold text-caption">
            Job Details
          </v-card-title>
          <v-card-text class="mt-2">
            <v-text-field
              v-model="editedItem.phoneNumber"
              label="Phone Number"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Phone number is required']"
            ></v-text-field>

            <v-select
              v-model="editedItem.gender"
              :items="['Male', 'Female', 'Other']"
              label="Gender"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Gender is required']"
            ></v-select>

            <v-text-field
              v-model="editedItem.nationality"
              label="Nationality"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Nationality is required']"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.currentLocation"
              label="Current Location"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Current Location is required']"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.currentCompany"
              label="Current Company"
              readonly
              variant="outlined"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.currentPosition"
              label="Current Position (Optional)"
              readonly
              variant="outlined"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.noticePeriod"
              label="Notice Period"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Notice Period is required']"
            ></v-text-field>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">
          <v-card-title class="text-center font-weight-bold text-caption">
            Job Details (continuation)
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="editedItem.visaStatus"
              :items="['Sponsored', 'Self', 'Other']"
              label="Visa Status"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Visa Status is required']"
            ></v-select>
            <v-select
              v-model="editedItem.careerLevel"
              :items="['Entry Level', 'Mid Level', 'Senior Level', 'Executive']"
              label="Career Level"
              readonly
              variant="outlined"
              :rules="[(v) => !!v || 'Career Level is required']"
            ></v-select>
            <v-text-field
              v-model="editedItem.salaryExpectations"
              label="Salary Expectations"
              readonly
              variant="outlined"
              type="number"
              :rules="[(v) => !!v || 'Salary Expectations are required']"
            ></v-text-field>

            <v-select
              v-model="editedItem.educationLevel"
              :items="[
                'High School',
                'Associate Degree',
                'Bachelor’s Degree',
                'Master’s Degree',
                'PhD',
              ]"
              label="Education Level"
              variant="outlined"
              readonly
              :rules="[(v) => !!v || 'Education Level is required']"
            ></v-select>

            <v-select
              v-model="editedItem.commitment"
              :items="['Full-time', 'Part-time', 'Freelance', 'Internship']"
              label="Commitment"
              variant="outlined"
              readonly
              :rules="[(v) => !!v || 'Commitment is required']"
            ></v-select>

            <v-textarea
              v-model="editedItem.workExperience"
              label="Work Experience"
              variant="outlined"
              readonly
              :rules="[(v) => !!v || 'Work Experience is required']"
            ></v-textarea>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="5">
          <v-card-title class="text-center font-weight-bold text-caption">
            Location
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="editedItem.cityId"
              :items="cities"
              readonly
              item-value="id"
              item-text="title"
              variant="outlined"
              label="Where would you like to work?"
              :rules="[(v) => !!v || 'Location is required']"
            ></v-autocomplete>

            <!-- Location Map -->
            <v-card class="mx-auto" elevation="0" max-width="600">
              <v-card-text class="bg-surface-light pt-4">
                <GoogleMap :cities="selectedCity"></GoogleMap>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-window-item>

        <!-- step 5: Payment & EVC -->
        <v-window-item :value="6">
          <v-card-title class="text-center text-caption font-weight-bold"
            >Payment & Submit EVC Code</v-card-title
          >
          <v-card-text>
            <!-- editedItem.listingId -->
            <div class="text-center">
              <v-icon
                color="error"
                size="x-large"
                icon="mdi-contactless-payment-circle"
              ></v-icon>
              <div class="mt-2 text-caption font-weight-bold">
                <span class="">Payable Amount (USD):</span>
                <span class="text-error font-weight-black">{{
                  " " + payableAmount
                }}</span>
              </div>
            </div>
            <v-text-field
              v-model="editedItem.transactionReference"
              label="EVC Code"
              class="mt-6"
              variant="outlined"
              :rules="[(v) => !!v || 'EVC is required']"
            ></v-text-field>
          </v-card-text>
        </v-window-item>
      </v-window>

      <!-- Navigation Buttons -->
      <v-card-actions>
        <v-icon
          v-if="step > 1"
          color="error"
          size="large"
          icon="mdi-chevron-left"
          @click="step--"
        ></v-icon>
        <v-spacer></v-spacer>
        <v-btn v-if="step < 5" color="error" variant="outlined" @click="step++">
          Next
        </v-btn>

        <v-btn
          v-if="step === 5"
          variant="outlined"
          color="blue"
          @click="step--"
        >
          Back
        </v-btn>
        <v-btn
          v-if="step === 6"
          variant="outlined"
          color="blue"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import GroupsService from "../../services/group.services.js";
import SubcategoriesService from "../../services/sub.category.services.js";
import CitiesService from "../../services/cities.services.js";
import CategoryListingService from "../../services/category.listing.service.js";
import ListingCostService from "../../services/listing.cost.service.js";
import TransactionsService from "../../services/transaction.service.js";
import DraftService from "../../services/draft.services.js";
import GoogleMap from "@/components/GoogleMapComponent.vue";

export default {
  name: "JobList",
  props: {
    Listing: {
      type: Object,
      required: true,
    },
  },
    components: {
    GoogleMap,
  },
  data: () => ({
    step: 1,
    subCategories: [], 
    groups: [], 
    cities: [],
    listingCosts: [],
    selectedCity: [],
    editedItem: {
      title: "",
      phoneNumber: "",
      cvSummary: "",
      gender: "",
      nationality: "",
      cv: null,
      currentLocation: "",
      currentCompany: "",
      currentPosition: "",
      noticePeriod: "",
      visaStatus: "",
      careerLevel: "",
      salaryExpectations: "",
      workExperience: "",
      educationLevel: "",
      commitment: "",
      desiredLocation: "",
      subCategoryId: "",
      groupId: "",
      cityId: "",
      userId: "",
    },

    defaultItem: {
      title: "",
      phoneNumber: "",
      cvSummary: "",
      gender: "",
      nationality: "",
      cv: null,
      currentLocation: "",
      currentCompany: "",
      currentPosition: "",
      noticePeriod: "",
      visaStatus: "",
      careerLevel: "",
      salaryExpectations: "",
      workExperience: "",
      educationLevel: "",
      commitment: "",
      desiredLocation: "",
      subCategoryId: "",
      groupId: "",
      cityId: "",
      userId: "",
    },
  }),

  

  computed: {
    filteredGroups() {
      if (this.editedItem.subCategoryId) {
        return this.groups.filter(
          (group) => group.subCategoryId === this.editedItem.subCategoryId
        );
      } else {
        return this.groups;
      }
    },

    userDraft() {
      return this.$store.getters["category/getDraftedListing"];
    },

    payableAmount() {
      console.log("the cat", this.listingCosts);
      const cost = this.listingCosts.find(
        (cost) => cost.categoryId == this.selectedCategory._id
      );
      return cost ? cost.amount : 0;
    },

    selectedCategory() {
      return this.$store.getters["category/getCategory"];
    },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    user() {
      return this.loggedInUser || {};
    },
  },

  created() {
    this.getGroups();
    this.getSubcategories();
    this.getCities();
    this.getlistingCosts();
    this.setDraftData();

    this.editedItem = this.Listing ? { ...this.Listing.activeRelation } : {};
    this.editedItem.cv = this.Listing.files;
    this.editedItem.title = this.Listing.title;
    this.editedItem.images = this.Listing.files;
    this.editedItem.cvSummary = this.Listing.description;
    this.editedItem.condition = this.Listing.condition;
    this.editedItem.usage = this.Listing.usage;
    this.selectedCity = Array.isArray(this.Listing.city) ? this.Listing.city : [this.Listing.city];
  },

  methods: {
    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getGroups() {
      return GroupsService.index().then(
        (response) => {
          if (response.status == 200) {
            this.groups = response.data.data;
          } else {
            this.groups = [];
          }
        },
        (error) => {
          this.groups = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    setDraftData() {
      if (this.userDraft) {
        this.editedItem = { ...this.userDraft };
        this.editedItem.draftId = this.userDraft.id;
        this.editedItem.pricePerYear = this.userDraft.price;
        this.editedItem.userId = this.userDraft.user._id;
        this.editedItem.location = this.userDraft.cityId;
        this.editedItem.categoryId = this.defaultItem.categoryId;
        this.editedItem.groupIdId = this.defaultItem.groupId;
      } else {
        this.editedItem = this.defaultItem;
      }
      console.log("set draft", this.userDraft);
    },

    getlistingCosts() {
      return ListingCostService.index().then(
        (response) => {
          if (response.status == 200) {
            this.listingCosts = response.data.data;
            this.loading = false;
          } else {
            this.listingCosts = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listingCosts = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getSubcategories() {
      return SubcategoriesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.subCategories = response.data.data;
          } else {
            this.subCategories = [];
          }
        },
        (error) => {
          this.subCategories = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },
    getCities() {
      return CitiesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data;
          } else {
            this.cities = [];
          }
        },
        (error) => {
          this.cities = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },
    save() {
      this.editedItem.userId = this.user._id;
      this.editedItem.categoryId = this.selectedCategory._id;
      if (this.editedItem.id) {
        // Update existing item
        CategoryListingService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            if (response.status === 200) {
              this.$store.dispatch("alert/success", response.data.message);
              // this.$store.dispatch("category/clearDraft");
              console.log("Item updated:", response.data);
            } else {
              this.$store.dispatch("alert/error", "Failed to update the item.");
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error.message);
            console.log("Update failed:", error);
          });
      } else {
        // Create new item
        CategoryListingService.store(this.editedItem)
          .then((response) => {
            if (response.status === 201) {
              this.$store.dispatch("alert/success", response.data.message);
              this.$store.dispatch("category/clearDraft");
              this.step = 5;
              console.log("Item created:", response.data);
            } else {
              this.$store.dispatch("alert/error", "Failed to create the item.");
            }
          })
          .catch((error) => {
            this.$store.dispatch("alert/error", error.message);
            console.log("Creation failed:", error);
          });
      }
    },

    submit() {
      this.editedItem.userId = this.user._id;
      this.editedItem.categoryId = this.selectedCategory._id;
      this.editedItem.amount = this.payableAmount;
      console.log("what i see", this.editedItem);
      TransactionsService.store(this.editedItem)
        .then((response) => {
          if (response.status === 201) {
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/");
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error.message);
          console.log("Creation failed:", error);
        });
    },

    saveDraft() {
      this.editedItem.userId = this.user._id;
      this.editedItem.categoryId = this.selectedCategory._id;
      DraftService.store(this.editedItem)
        .then((response) => {
          if (response.status === 201) {
            this.$store.dispatch("alert/success", response.data.message);
            // console.log("Item created:", response.data);
          } else {
            this.$store.dispatch(
              "alert/error",
              "Failed to save item in draft."
            );
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error.message);
          console.log("Creation failed:", error);
        });
    },

    resetForm() {
      // Reset the form
      this.editedItem = {
        title: "",
        phoneNumber: "",
        cvSummary: "",
        gender: "",
        nationality: "",
        cv: null,
        currentLocation: "",
        currentCompany: "",
        currentPosition: "",
        noticePeriod: "",
        visaStatus: "",
        careerLevel: "",
        salaryExpectations: "",
        workExperience: "",
        educationLevel: "",
        commitment: "",
        desiredLocation: "",
        subCategoryId: "",
        title: "",
        groupId: "",
        cityId: "",
      };
      this.step = 1; // Reset step to 1
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
