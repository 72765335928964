<template>
  <div>
    <VueApexCharts />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  components: {
    VueApexCharts,
  },
  data: () => ({
    stroke: {
      curve: "smooth",
    },
  }),
};
</script>
