// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
import fileHeader from './file-header';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const API_URL = process.env.VUE_APP_API_URL;

class ListingService {

    index() {
        return axios.get(API_URL + 'listings', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'listings/' + id, { headers: authHeader() })
    }
    store(resource) {
        return axios.post(API_URL + 'listings', resource, { headers: fileHeader() })
    }
    update(resource) {
        return axios.put(API_URL + 'listings/' + resource.id, resource, { headers: fileHeader() })
    }
    delete(resource) {
        return axios.delete(API_URL + 'listings/' + resource.id, { headers: authHeader() })
    }

    getApprovedOpenListing() {
        return axios.get(API_URL + 'get-listings', { headers: authHeader() })
    }

    getListingFile(id) {
        return axios.get(API_URL + 'files/' + id, { headers: authHeader() })
    }

    filterListing(resource) {
        return axios.post(API_URL + 'filter-listings', resource, { headers: fileHeader() })
    }
    
}

export default new ListingService()