// plugins/logoutPlugin.js
export default {
  install(app) {
    // Make sure the handleLogout function is correctly added to global properties
    app.config.globalProperties.$handleLogout = function() {
      this.$store.commit('logout');
      this.$store.dispatch('resetInactivityTimeout');
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('resetInactivityTimeout');
      this.$router.push('/login');
      this.$store.dispatch('alert/success', 'Logout Successfully');
    };
  }
};
