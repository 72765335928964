<template>
  <div>
    <!-- <v-app-bar class="text-center" :elevation="1">
        <v-img
          :src="require('@/assets/suuq.logo.svg')"
          alt="SUUQ Logo"
          class="ml-8"
          @click="logoRoute"
          cover
          max-width="100"
        />
    </v-app-bar> -->
    <div class="d-flex align-center justify-center" style="height: 90vh">
      <v-card elevation="0" width="400" class="mx-auto">
        <v-card-title class="text-center ma-5 mb-5">
          <v-carousel
            height="200"
            show-arrows="hover"
            cycle
            hide-delimiter-background
            hide-delimiters
            class=""
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="require('@/assets/' + item.src)"
            >
              <div
                class="d-flex fill-height justify-center align-center text-center text-black mt-15"
              >
                <div class="mt-15">{{ slides[i] }}</div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-card-title>
        <v-card-text>
          <v-form class="ma-3">
            <v-btn
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              elevation="0"
              variant="outlined"
              class="mt-3 text-left"
              prepend-icon="mdi-facebook"
              @click="OauthLogin('facebook')"
            >
              <template v-slot:prepend>
                <v-icon size="large" class="ml-n9" color="primary"></v-icon>
              </template>
              <span
                class="text-button text-capitalize font-weight-bold text-grey"
              >
                Continue <span class="text-lowercase">with</span> Facebook</span
              >
            </v-btn>
            <v-btn
              v-model="editedItem.google"
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              elevation="0"
              variant="outlined"
              class="mt-3"
              @click="OauthLogin('google')"
            >
              <template v-slot:prepend>
                <v-icon size="large" class="ml-n11" role="img">
                  <v-img
                    :width="100"
                    aspect-ratio="16/9"
                    cover
                    :src="googleIcon"
                  ></v-img>
                </v-icon>
              </template>
              <span
                class="text-button text-capitalize font-weight-bold text-grey"
              >
                Continue <span class="text-lowercase">with</span> Google</span
              >
            </v-btn>
            <v-btn
              v-model="editedItem.apple"
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              elevation="0"
              variant="outlined"
              class="mt-3"
              prepend-icon="mdi-apple"
              @click="OauthLogin('apple')"
            >
              <template v-slot:prepend>
                <v-icon size="large" class="ml-n12" color="black"></v-icon>
              </template>
              <span
                class="text-button text-capitalize font-weight-bold text-grey"
              >
                Continue <span class="text-lowercase">with</span> Apple</span
              >
            </v-btn>
            <v-btn
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              elevation="0"
              variant="outlined"
              class="mt-3"
              prepend-icon="mdi-email"
              @click="emailLogin()"
            >
              <template v-slot:prepend>
                <v-icon size="large" class="ml-n12" color="red"></v-icon>
              </template>
              <span
                class="text-button text-capitalize font-weight-bold text-grey"
              >
                Continue <span class="text-lowercase">with</span> Email</span
              >
            </v-btn>

            <div class="text-center">
              <v-btn
                @click="register"
                variant="text"
                class="mt-2 ml-n2 mb-n7 text-black"
                size="x-small"
                color="red"
                >Don't have an account? create one</v-btn
              >
              <div class="mt-6 text-caption">
                <span class="text-grey">
                  By signing up I agree to the
                  <v-btn
                    @click="terms"
                    variant="text"
                    class="text-none ml-n1"
                    size="x-small"
                    color="blue"
                    >Terms and Conditions</v-btn
                  >
                  <span class="ml-n1"> and </span>
                  <v-btn
                    @click="policies"
                    variant="text"
                    class="text-none ml-n2"
                    size="x-small"
                    color="blue"
                    >Privacy Policies</v-btn
                  >
                </span>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      user: {},
      socialData: {},
      editedItem: {
        provider: "",
      },
      loading: false,
      googleIcon: require("@/assets/google-color-icon.svg"),

      slides: [
        "Login to call the seller",
        "Login to post an ad",
        "Login to favorite an ad",
      ],
      items: [
        {
          src: "call.svg",
        },
        {
          src: "favoritead.svg",
        },
        {
          src: "paa.svg",
        },
      ],
    };
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    emailLogin() {
      this.$router.push(this.$route.query.redirect || "/login");
    },
    register() {
      this.$router.push(this.$route.query.redirect || "/register");
    },

    terms() {
      this.$router.push(this.$route.query.redirect || "/terms");
    },

    policies() {
      this.$router.push(this.$route.query.redirect || "/policies");
    },

    OauthLogin(provider) {
      this.loading = false;
      this.editedItem.provider = provider;
      AuthService.oathLogin(provider).then(
        (response) => {
          if (response.status === 200 && response.data) {
            if (response.data.data.url) {
              window.location.href = response.data.data.url;
            }
            console.log("response", response);
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.$router.push(
              this.$route.query.redirect || "/forgotten-password"
            );
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response && error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
          }
          this.$store.dispatch(
            "alert/error",
            error.response?.data?.message
              ? error.response?.data?.message
              : error
          );
        }
      );
    },
  },
};
</script>