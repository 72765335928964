<template>
  <div>
    <v-app-bar class="text-center" :elevation="1">
             <!-- Clickable Logo -->
        <v-img
          :src="require('@/assets/suuq.logo.svg')"
          alt="SUUQ Logo"
          @click="logoRoute"
        />
    </v-app-bar>
    <div class="d-flex align-center justify-center" style="height: 90vh">
      <v-card elevation="0" width="400" class="mx-auto">
        <v-card-title class="text-center mb-5">
          Login with your email
        </v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="form" class="ma-3">
            <v-text-field
              v-model="user.email"
              :rules="[rules.required]"
              class="mb-2"
              clearable
              variant="outlined"
              label="Email"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              label="Password"
              hint="At least 8 characters"
              counter
              variant="outlined"
              @click:append-inner="show1 = !show1"
            ></v-text-field>
            <v-btn
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              elevation="0"
              class="mt-3"
              @click="handleLogin"
            >
              <span class="text-white"> Login</span>
            </v-btn>
            <div class="text-center">
              <v-btn
                @click="forgottenPassword"
                variant="text"
                class="ml-n2 mb-n5"
                size="x-small"
                color="red"
                >Forgot your password?</v-btn
              >
              <div class="mt-6 text-caption">
                <span class="text-grey">
                  By signing up I agree to the
                  <v-btn
                    @click="terms"
                    variant="text"
                    class="text-none ml-n1"
                    size="x-small"
                    color="blue"
                    >Terms and Conditions</v-btn
                  >
                  <span class="ml-n1"> and </span>
                  <v-btn
                    @click="policies"
                    variant="text"
                    class="text-none ml-n2"
                    size="x-small"
                    color="blue"
                    >Privacy Policies</v-btn
                  >
                </span>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import User from "../../models/user";

import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      user: new User("", "", ""),
      valid: false,
      remember: 0,
      resultArray: Boolean,
      loading: false,
      timeout: 2000,
      show1: false,
      show2: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  components: {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    validation() {
      return this.$store.state.tender.validationErrors;
    },
  },

  created() {
    localStorage.clear();
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    terms() {
      this.$router.push(this.$route.query.redirect || "/terms");
    },

    policies() {
      this.$router.push(this.$route.query.redirect || "/policies");
    },

    getValidationErrors() {
      axios.interceptors.response.use(
        (res) => {
          this.$store.dispatch("alert/error", []);
          return res;
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.errors);
          }

          return error;
        }
      );
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    goToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },

    forgottenPassword() {
      this.$router.push(this.$route.query.redirect || "/forgotten-password");
    },

    async handleLogin() {
      await this.validate();
      if (this.valid.valid) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch("login", this.user);
          if (response.statusCode === 200 && response.data) {
            console.log("changes", response);
            response.data.role.slug === "admin"
              ? this.$router.push(this.$route.query.redirect || "/admin")
              : this.$router.push(this.$route.query.redirect || "/");

            this.$store.dispatch("alert/success", response.message);
            this.$store.commit("login");
            this.loading = false;
          }
        } catch (error) {
          console.log(error, "errrrr");
          this.loading = false;
          this.$store.dispatch(
            "alert/error",
            error.response.data?.message ||
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}
</style>