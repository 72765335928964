<template>
  <v-card class="mx-auto mt-3" elevation="0" max-width="600">
    <v-window v-model="step">
      <!-- Step 1: Category, Group, and Images -->
      <v-window-item :value="1">
        <v-card-title class="text-center font-weight-bold text-caption"
          >Selected Category & other details</v-card-title
        >
        <v-card-text class="mt-2">
          <v-text-field
            label="Title"
            v-model="editedItem.title"
            readonly
            variant="outlined"
          ></v-text-field>
          <v-autocomplete
            v-model="editedItem.subCategoryId"
            :items="subCategories"
            readonly
            item-value="id"
            item-text="title"
            variant="outlined"
            label="Select Subcategory"
            :rules="[(v) => !!v || 'Subcategory is required']"
          ></v-autocomplete>

          <!-- Property For Sale/For Rent -->
          <v-autocomplete
            v-model="editedItem.propertyType"
            readonly
            :items="['For Sale', 'For Rent']"
            variant="outlined"
            label="Select Property Type"
            :rules="[(v) => !!v || 'Property type is required']"
          ></v-autocomplete>

          <v-autocomplete
            v-model="editedItem.groupId"
            :items="groups"
            readonly
            item-value="id"
            item-text="title"
            label="Select Group"
            variant="outlined"
            :rules="[(v) => !!v || 'Group is required']"
          ></v-autocomplete>

          <v-text-field
            v-model="editedItem.phoneNumber"
            variant="outlined"
            readonly
            label="Phone number"
            :rules="[(v) => !!v || 'Phone number is required']"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.url"
            variant="outlined"
            readonly
            label="Youtube url (optional)"
          ></v-text-field>
        </v-card-text>
      </v-window-item>
      <!-- step 2: images  -->
      <v-window-item :value="2">
        <v-card-title class="text-center font-weight-bold text-caption">
          Property Images
        </v-card-title>
        <v-card elvation="0" class="mb-10">
          <v-carousel
            height="400"
            show-arrows="hover"
            cycle
            hide-delimiter-background
          >
            <v-carousel-item v-for="(file, i) in editedItem.files" :key="i">
              <v-sheet height="100%">
                <v-img
                  class="bg-grey-lighten-2 mt-4"
                  height="500"
                  :src="`data:${file.mime_type};base64,${file.base64_data}`"
                  cover
                ></v-img>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-window-item>

      <!-- Step 3: Property Basic Details -->
      <v-window-item :value="3">
        <v-card-title class="text-center font-weight-bold text-caption">
          Property Details
        </v-card-title>
        <v-card-text class="mt-2">
          <v-text-field
            v-model="editedItem.pricePerYear"
            label="Price"
            readonly
            variant="outlined"
            type="number"
            :rules="[(v) => !!v || 'Price is required']"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.size"
            label="Size (sq ft)"
            readonly
            variant="outlined"
            type="number"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.bedrooms"
            v-if="
              selectedSubcategory && selectedSubcategory.slug !== 'land-sale'
            "
            label="Bedrooms"
            variant="outlined"
            readonly
            type="number"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.bathrooms"
            v-if="
              selectedSubcategory && selectedSubcategory.slug !== 'land-sale'
            "
            label="Bathrooms (Optional)"
            variant="outlined"
            readonly
            type="number"
          ></v-text-field>
        </v-card-text>

        <v-textarea
          v-model="editedItem.description"
          label="Describe your property"
          class="mt-n2 mx-4"
          variant="outlined"
          readonly
          :rules="[(v) => !!v || 'Description is required']"
        ></v-textarea>
      </v-window-item>

      <!-- Step 4: Additional Details -->
      <v-window-item :value="4">
        <v-card-title class="text-center text-caption font-weight-bold"
          >Additional Information</v-card-title
        >
        <v-card-text class="mt-2">
          <v-select
            v-model="editedItem.rentPaid"
            variant="outlined"
            readonly
            :items="['Monthly', 'Quarterly', 'Yearly']"
            label="Rent is paid (Optional)"
          ></v-select>

          <v-text-field
            v-model="editedItem.propertyReferenceId"
            label="Property Reference ID (Optional)"
            readonly
            variant="outlined"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.minimumContractPeriod"
            label="Minimum Contract Period (in months, Optional)"
            variant="outlined"
            readonly
            type="number"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.noticePeriod"
            label="Notice Period (in months, Optional)"
            variant="outlined"
            readonly
            type="number"
          ></v-text-field>

          <v-text-field
            v-model="editedItem.maintenanceFee"
            label="Maintenance Fee (Optional)"
            variant="outlined"
            readonly
            type="number"
          ></v-text-field>

          <v-switch
            v-model="editedItem.isFurnished"
            inset
            readonly
            color="error"
            label="Is it furnished? (Optional)"
          ></v-switch>
        </v-card-text>
      </v-window-item>

      <!-- Step 5: Amenities and Features -->
      <v-window-item :value="5" class="mt-n3">
        <v-card-title class="text-center text-caption font-weight-bold"
          >Features and Amenities</v-card-title
        >
        <v-card-text class="mt-2">
          <v-select
            v-model="editedItem.occupancyStatus"
            :items="['Vacant', 'Occupied']"
            variant="outlined"
            readonly
            label="Occupancy status (Optional)"
          ></v-select>

          <v-switch
            v-model="editedItem.maidsRoom"
            inset
            class="mt-n4"
            color="error"
            readonly
            label="Maids Room (Optional)"
          ></v-switch>
          <v-switch
            v-model="editedItem.studyRoom"
            inset
            class="mt-n4"
            color="error"
            label="Study (Optional)"
            readonly
          ></v-switch>
          <v-switch
            v-model="editedItem.centralACHeating"
            inset
            class="mt-n4"
            color="error"
            label="Central A/C & Heating (Optional)"
            readonly
          ></v-switch>
          <v-switch
            v-model="editedItem.conciergeService"
            inset
            class="mt-n4"
            color="error"
            label="Concierge Service (Optional)"
            readonly
          ></v-switch>
          <v-switch
            v-model="editedItem.balcony"
            inset
            class="mt-n4"
            color="error"
            label="Balcony (Optional)"
            readonly
          ></v-switch>
          <v-switch
            v-model="editedItem.privateGarden"
            inset
            class="mt-n4"
            color="error"
            label="Private Garden (Optional)"
            readonly
          ></v-switch>
          <v-switch
            v-model="editedItem.privatePool"
            inset
            class="mt-n4"
            color="error"
            label="Private Pool (Optional)"
            readonly
          ></v-switch>
          <v-switch
            v-model="editedItem.petsAllowed"
            inset
            class="mt-n4 mb-n9"
            color="error"
            label="Pets Allowed (Optional)"
            readonly
          ></v-switch>
        </v-card-text>
      </v-window-item>

      <!-- Step 6: Location and Map -->
      <v-window-item :value="6">
        <v-card-title class="text-center text-caption font-weight-bold"
          >Location</v-card-title
        >
        <v-card-text>
          <v-autocomplete
            v-model="editedItem.cityId"
            :items="cities"
            item-value="id"
            item-text="title"
            variant="outlined"
            label="Select Location"
            readonly
            :rules="[(v) => !!v || 'Location is required']"
          ></v-autocomplete>

          <!-- Location Map -->
          <v-card class="mx-auto" elevation="0" max-width="600">
            <v-card-text class="bg-surface-light pt-4">
              <GoogleMap :cities="selectedCity"></GoogleMap>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-window-item>

      <!-- step 6: Payment & EVC -->
      <v-window-item :value="6">
        <v-card-title class="text-center text-caption font-weight-bold"
          >Payment & Submit EVC Code</v-card-title
        >
        <v-card-text>
          <!-- editedItem.listingId -->
          <div class="text-center">
            <v-icon
              color="error"
              size="x-large"
              icon="mdi-contactless-payment-circle"
            ></v-icon>
            <div class="mt-2 text-caption font-weight-bold">
              <span class="">Payable Amount (USD):</span>
              <span class="text-error font-weight-black">{{
                " " + payableAmount
              }}</span>
            </div>
          </div>
          <v-text-field
            v-model="editedItem.transactionReference"
            label="EVC Code"
            class="mt-6"
            variant="outlined"
            :rules="[(v) => !!v || 'EVC is required']"
          ></v-text-field>
        </v-card-text>
      </v-window-item>
    </v-window>

    <!-- Navigation Buttons -->
    <v-card-actions>
      <v-icon
        v-if="step > 1"
        color="error"
        size="large"
        icon="mdi-chevron-left"
        @click="this.step--"
      ></v-icon>
      <v-spacer></v-spacer>
      <v-btn
        v-if="step < 6"
        color="error"
        variant="outlined"
        @click="this.step++"
      >
        Next
      </v-btn>
      <v-btn
        v-if="step === 6"
        variant="outlined"
        color="blue"
        @click="this.step--"
      >
        Back
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CitiesService from "../../services/cities.services.js";
import GroupsService from "../../services/group.services.js";
import SubCategoriesService from "../../services/sub.category.services.js";
import GoogleMap from "@/components/GoogleMapComponent.vue";

export default {
  name: "PropertyList",
  props: {
    Listing: {
      type: Object,
      required: true,
    },
  },
  components: {
    GoogleMap,
  },
  data: () => ({
    step: 1,
    cities: [],
    groups: [],
    subCategories: [],
    selectedCity: [],
    editedItem: {
      subCategoryId: "",
      groupId: "",
      draftId: "",
      categoryId: "",
      url: "",
      images: [],
      phoneNumber: "",
      pricePerYear: null,
      description: "",
      size: null,
      bedrooms: null,
      bathrooms: null,
      isFurnished: false,
      rentPaid: "",
      title: "",
      propertyReferenceId: "",
      minimumContractPeriod: null,
      noticePeriod: null,
      maintenanceFee: null,
      occupancyStatus: "",
      maidsRoom: false,
      studyRoom: false,
      centralACHeating: false,
      conciergeService: false,
      balcony: false,
      privateGarden: false,
      privatePool: false,
      petsAllowed: false,
      location: "",
      userId: "",
      files: {},
    },
  }),



  computed: {
    selectedCategory() {
      return this.$store.getters["category/getCategory"];
    },
  },

  created() {
    this.getCities();
    this.getsubCategories();
    this.getGroups();

    this.editedItem = this.Listing ? { ...this.Listing.activeRelation } : {};
    this.editedItem.files = this.Listing.files;
    this.editedItem.isFurnished = JSON.parse(this.editedItem.isFurnished);
    this.editedItem.isFurnished = JSON.parse(this.editedItem.isFurnished);
    this.editedItem.maidsRoom = JSON.parse(this.editedItem.maidsRoom);
    this.editedItem.studyRoom = JSON.parse(this.editedItem.studyRoom);
    this.editedItem.centralACHeating = JSON.parse(
      this.editedItem.centralACHeating
    );
    this.editedItem.conciergeService = JSON.parse(
      this.editedItem.conciergeService
    );
    this.editedItem.balcony = JSON.parse(this.editedItem.balcony);
    this.editedItem.privateGarden = JSON.parse(this.editedItem.privateGarden);
    this.editedItem.privatePool = JSON.parse(this.editedItem.privatePool);
    this.editedItem.petsAllowed = JSON.parse(this.editedItem.petsAllowed);
    this.selectedCity = Array.isArray(this.Listing.city) ? this.Listing.city : [this.Listing.city];
  },

  methods: {
    getCities() {
      return CitiesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data;
            this.loading = false;
          } else {
            this.cities = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getsubCategories() {
      return SubCategoriesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.subCategories = response.data.data;
            this.loading = false;
          } else {
            this.subCategories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.subCategories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getGroups() {
      return GroupsService.index().then(
        (response) => {
          if (response.status == 200) {
            this.groups = response.data.data;
            this.loading = false;
          } else {
            this.groups = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.groups = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },
  },
};
</script>