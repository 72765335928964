const state = {
    selectedCategory: null,
    draftedListing: null,
    selectedItem: null,
  };
  
  const mutations = {
    SET_CATEGORY(state, category) {
      state.selectedCategory = category;
    },

    SET_DRAFT(state, draft) {
      state.draftedListing = draft;
    },

    SET_SELECTED_ITEM(state, item) {
      state.selectedItem = item;
    },

    CLEAR_DRAFT(state) {
      state.draftedListing = null;
    },
  };
  
  const actions = {
    setCategory({ commit }, category) {
      commit('SET_CATEGORY', category);
    },

    setDraft({ commit }, draft) {
      commit('SET_DRAFT', draft);
    },

    setSelectedItem({ commit }, item) {
      commit('SET_SELECTED_ITEM', item);
    },

    clearDraft({ commit }) {
      commit("CLEAR_DRAFT");
    },
  };
  
  const getters = {
    getCategory: (state) => state.selectedCategory,
    getDraftedListing: (state) => state.draftedListing,
    getSelectedItem: (state) => state.selectedItem,
  };
  
  export const category = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  