<template>
  <div>
    <h1>Social Auth Data</h1>
    <div v-if="socialData">
      <pre>{{ socialData }}</pre>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { subscribeToSocialAuth } from '@/services/social.auth.pusher.js';

export default {
  setup() {
    const socialData = ref(null);

    // Subscribe to the Pusher event on component mount
    onMounted(() => {
      subscribeToSocialAuth((data) => {
        console.log('Received data:', data);
        socialData.value = data.data;
      });
    });

    return {
      socialData,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
