<template>
  <v-row class="mr-n5">
    <v-col cols="12">
      <v-stepper>
        <v-stepper-header>
          <v-stepper-item title="Category"></v-stepper-item>
          <v-divider></v-divider>
          <v-stepper-item title="Sub-Category"></v-stepper-item>
          <v-divider></v-divider>
          <v-stepper-item title="Group"></v-stepper-item>
          <v-divider></v-divider>
          <v-stepper-item title="Create an ad"></v-stepper-item>
        </v-stepper-header>
      </v-stepper>
    </v-col>
    <v-col cols="12" sm="4" md="4" v-for="(item, i) in overview" :key="i">
      <div class="text-caption">
        <v-alert
          color="grey-lighten-4"
          height="150"
          border="start"
          border-color="blue"
          elevation="0"
          :icon="item.avatar"
          :title="item.title"
        >
          <v-card color="grey-lighten-4" height="85">
            <span> {{ item.stat }}</span>
          </v-card>
        </v-alert>
      </div>
    </v-col>
    <v-col cols="12">
      <!-- graph card -->
      <v-card color="grey-lighten-4" height="420">
        <!-- <GraphComponent/> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import GraphComponent from '../components/Dashboard/LineGraphComponent.vue'
export default {
  components: {
    GraphComponent,
  },
  data: () => ({
    overview: [
      {
        title: "Users",
        avatar: "mdi-account-group",
        stat: "235",
      },
      {
        title: "Listings",
        avatar: "mdi-google-ads",
        stat: "235",
      },
      {
        title: "Disputes",
        avatar: "mdi-alert-octagon",
        stat: "235",
      },
    ],
  }),

  methods: {},
};
</script>
