<template>
  <div>
    <GoogleMap
      :api-key="googleMapsApiKey"
      :style="
        formattedCities.length > 1
          ? 'width: 100%; height: 510px'
          : 'width: 100%; height: 310px'
      "
      :center="center"
      :zoom="formattedCities.length > 1 ? 5 : 10"
    >
      <!-- Loop over each city and create a marker for it -->
      <Marker
        v-for="city in formattedCities"
        :key="city"
        :options="{
          position: { lat: parseFloat(city.lat), lng: parseFloat(city.lng) },
        }"
        @click="openInfoWindow(city)"
      >
        <InfoWindow v-if="activeCity === city">
          <div id="content">{{ city.title }}, {{ city.country.name }}</div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  </div>
</template>
<script>
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";

export default {
  name: "GoogleMapComponent",
  components: {
    GoogleMap,
    Marker,
    InfoWindow,
  },
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      center: { lat: 2.0392, lng: 45.3419 },
      infowindow: false,
      activeCity: null,
    };
  },
  computed: {
    formattedCities() {
      if (Array.isArray(this.cities)) {
        return this.cities;
      } else if (this.cities && typeof this.cities === "object") {
        return [this.cities];
      }
      return [];
    },
  },

  methods: {
    openInfoWindow(city) {
      this.activeCity = city;
    },
  },
};
</script>
