import AuthService from '../services/auth.service'
// const user = localStorage.getItem('user');
const user = JSON.parse(localStorage.getItem('user'));
console.log('userhere', user);
const initialState = user ? { status: { loggedIn: true }, user, selected_user: null } : { status: { loggedIn: false }, user: null, selected_user: null };
const snackbar = {
    show: false,
    message: '',
    color: ''
}


export const auth = {
    // namespaced: true,
    state: { ...initialState, snackbar },
    actions: {
        clearSnack({ commit }) {
            commit('clearSnack');
        },

        setSelectedUser({ commit }, item) {
            commit('setSelectedUser', item)
        },

        login({ commit }, user) {
            return AuthService.login(user).then(
                response => {
                    if (response.status == 200) {
                        console.log('ress', response);
                        commit('loginSuccess', response.data)
                        return Promise.resolve(response.data)
                    } else {
                        commit('loginFailure', response)
                        return Promise.reject(response)
                    }

                },
                error => {
                    commit('loginFailure', error)
                    return Promise.reject(error)
                }
            )
        },

        oauthLogin({ commit }, user) {
            return AuthService.oathLoginCallback(user).then(
                response => {
                    if (response.status == 200) {
                        commit('loginSuccess', response.data)
                        return Promise.resolve(response.data)
                    } else {
                        commit('loginFailure', response)
                        return Promise.reject(response)
                    }

                },
                error => {
                    commit('loginFailure', error)
                    return Promise.reject(error)
                }
            )
        },

        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },

    },

    mutations: {
        loginSuccess(state, response) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User Login Successful!'
            localStorage.setItem('user', JSON.stringify(response.data))
            localStorage.setItem('token', response.data.token)
            state.user = response.data
        },

        loginFailure(state, error) {
            console.log(error);
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message ||
                (error.response && error.response.data) ||
                error.mesage ||
                error.toString();
        },

        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },

        validationError(state, errors) {
            let msg = ''
            let errorsArray = Object.values(errors);
            console.log(errorsArray)
            errorsArray.forEach(element => {
                console.log(element)
                msg = msg + element + '.'
            });
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = msg
        },

        clearSnack(state) {
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },

        setSelectedUser(state, item) {
            state.selected_user = item
        },

    },
    getters: {
        user: (state) => state.user
    }

}