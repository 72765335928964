// src/services/social.auth.pusher.js
import Pusher from 'pusher-js';

// Initialize Pusher
const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  debug: true, 
});

// Subscribe to the public channel
export const subscribeToNotifications = (userId, callback) => {
  const channel = pusher.subscribe(`${userId}`);

  // Bind to the event
  channel.bind(`${userId}`, (data) => {
    callback(data);
  });
};


