<template>
  <v-footer prominent absolute class="bg-grey-lighten-2 mb-n3">
    <div class="mx-16" id="app">
      <v-row no-gutters>
        <v-list
          v-model="selection"
          v-for="link in links"
          :key="link"
          class="mx-7 font-weight-black text-none bg-grey-lighten-2"
          color="grey-lighten-2"
        >
          <v-list-subheader
            ><div class="font-weight-black">
              {{ link.title }}
            </div></v-list-subheader
          >
          <v-list-item
            v-for="(item, i) in link.routes"
            :key="i"
            :value="item"
            :to="item.route"
            color="black"
            class="bg-grey-lighten-2"
          >
            <v-list-item-title class="text-caption text-blue">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-col class="mt-4 ml-11" cols="12">
          <!-- <div class="text-caption">
            <span class="text-grey">suuqlogo</span>
          </div> -->
          <div class="text-caption">
            <v-icon color="grey" size="x-small" icon="mdi-copyright"></v-icon>
            <span class="text-grey">
              suuq.com {{ new Date().getFullYear() }}, All Rights
              Reserved.</span
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>
<script>
import CityService from "../../services/cities.services";
import CountryService from "../../services/country.services.js";
export default {
  name: "FooterComponent",
  data: () => ({
    links: [
      {
        title: "Company",
        routes: [
          {
            title: "About us",
            route: "",
          },
          {
            title: "Advertising",
            route: "",
          },
          {
            title: "Careers",
            route: "",
          },
          {
            title: "Terms of use",
            route: "/terms",
          },
          {
            title: "Privacy policy",
            route: "/policies",
          },
        ],
      },
      {
        title: "Somalia",
        routes: [],
      },
      {
        title: "Other Countries",
        routes: [
          {
            title: "Only in somalia",
            route: "",
          },
        ],
      },
      {
        title: "Get Social",
        routes: [
          {
            title: "Facebook",
            route: "",
          },
          {
            title: "Twitter",
            route: "",
          },
          {
            title: "Youtube",
            route: "",
          },
          {
            title: "Instagram",
            route: "",
          },
        ],
      },
      {
        title: "Support",
        routes: [
          {
            title: "Help",
            route: "",
          },
          {
            title: "Contact us",
            route: "",
          },
          {
            title: "Call us",
            route: "",
          },
        ],
      },
      {
        title: "Laguages",
        routes: [
          {
            title: "English",
            route: "",
          },
        ],
      },
    ],

    selection: null,
    cities: {},
    country: [],
  }),
  created() {
    this.getDefaultCountry();
    // this.getcities();
  },
  methods: {
    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getDefaultCountry() {
      return CountryService.defaultCountry().then(
        (response) => {
          if (response.status == 200) {
            this.country = response.data.data;
            this.getcities(this.country.id);
            this.updateOtherCountriesTitle(this.country.title);
            this.loading = false;
          } else {
            this.country = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.country = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    // get cities
    getcities(countryId) {
      return CityService.getCountryCities(countryId).then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data.slice(0, 6);
            this.updateSomaliaLinks();
            this.loading = false;
          } else {
            this.cities = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    updateSomaliaLinks() {
      const somaliaSection = this.links.find(
        (link) => link.title === "Somalia"
      );
      if (somaliaSection) {
        somaliaSection.routes = this.cities.map((city) => ({
          title: city.title,
          route: city.domain,
        }));
      }
    },

    updateOtherCountriesTitle(countryTitle) {
      const otherCountriesSection = this.links.find(
        (link) => link.title === "Other Countries"
      );
      if (otherCountriesSection) {
        otherCountriesSection.routes[0].title = countryTitle;
      }
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

v-main {
  flex: 1;
}
</style>