import axios from 'axios';
import authHeader from './auth-header';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const API_URL = process.env.VUE_APP_API_URL;

class CategoryListingService {
    index() {
        return axios.get(API_URL + 'category-listing', { headers: authHeader() });
    }

    show(id) {
        return axios.get(API_URL + 'category-listing/' + id, { headers: authHeader() });
    }

    store(resource) {
        const formData = this.toFormData(resource);
        return axios.post(API_URL + 'category-listing', formData, {
            headers: {
                ...authHeader(),
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    update(resource) {
        const formData = this.toFormData(resource);
        return axios.post(API_URL + 'category-listing/' + resource.id, formData, {
            headers: {
                ...authHeader(),
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    delete(resource) {
        return axios.delete(API_URL + 'category-listing/' + resource.id, { headers: authHeader() });
    }

    // Utility method to convert a resource object to FormData
    toFormData(resource) {
        const formData = new FormData();
        for (const key in resource) {
            if (Array.isArray(resource[key])) {
                resource[key].forEach((file, index) => {
                    formData.append(`${key}[${index}]`, file);
                });
            } else {
                formData.append(key, resource[key]);
            }
        }

        return formData;
    }
}

export default new CategoryListingService();
