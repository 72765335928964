<template>
  <div>
    <v-row no-gutter class="ma-1">
      <v-col cols="12" md="12" class="d-flex text-justify justify-center">
        <v-card width="820" variant="flat" rounded="sm" class="pa-1">
          <template v-slot:title>
            <div class="text-blue font-weight-bold">
              {{ tandc.title }}
            </div>
            <div class="text-caption">{{ tandc.effectiveDate }}</div>
          </template>
          <template v-slot:text>
            <div>
              <p class="text-caption text-justify">{{ tandc.intro }}</p>
            </div>
            <div class="mt-3">
              <v-alert
                v-for="(policy, index) in tandc.policies"
                :key="index"
                icon="mdi-square-small"
                variant=""
                class="my-n4"
              >
                <span v-if="policy.title" class="text-blue font-weight-bold">{{
                  policy.title + ":"
                }}</span>
                <div v-if="Array.isArray(policy.description)">
                  <v-alert
                    v-for="(point, index) in policy.description"
                    :key="index"
                    icon="mdi-square-small"
                    variant=""
                    class="my-n4"
                  >
                    <span class="text-caption text-justify">{{
                      " " + point.point
                    }}</span>
                  </v-alert>
                </div>

                <!-- </span> -->
                <span v-else class="text-caption text-justify">{{
                  " " + policy.description
                }}</span>
                <div v-if="policy.list">
                  <v-alert
                    v-for="(list, index) in policy.list"
                    :key="index"
                    icon="mdi-square-small"
                    variant=""
                    class="my-n4"
                  >
                    <span
                      v-if="list.title"
                      class="text-blue font-weight-bold"
                      >{{ list.title + ":" }}</span
                    >
                    <span class="text-caption text-justify">{{
                      " " + list.description
                    }}</span>
                  </v-alert>
                </div>
              </v-alert>
            </div>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-n5">
      <v-col cols="12">
        <FooterComponent></FooterComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FooterComponent from "@/components/footer/FooterComponent.vue";
export default {
  components: {
    FooterComponent,
  },
  data: () => ({
    tandc: {
      title: "Privacy Policy",
      effectiveDate: "6 February 2024",
      intro:
        'Your privacy is extremely important to SUUQ. We are committed to protecting your Personal Data (as that policy is defined further below). We want to be transparent with you about how we collect and use your Personal Data in making our website and mobile applications ("Platform") available to you and tell you about your privacy rights and how the law protects you. This Privacy Policy aims to give you information on how SUUQ collects and processes your Personal Data through your use of this Platform. By using the Platform, you agree to the collection, use and transfer of your Personal Data as set out in this Privacy Policy.',
      policies: [
        {
          title: "What does this privacy policy cover?",
          description: [
            { point: "Who we are and how to contact us" },
            { point: "Personal data we collect from you" },
            { point: "Personal data we collect from other titles" },
            { point: "How we use your personal data and why" },
            { point: "Who we share your personal data with" },
            { point: "How we keep your personal data secure" },
            { point: "How long we store your personal data" },
            { point: "Your rights in relation to your personal data" },
            { point: "Marketing Communications" },
            { point: "Our policy on minors" },
            { point: "Third party links" },
            { point: "Changes to this privacy policy" },
          ],
        },
        {
          title: "Who we are and how to contact us",
          description: "",
          list: [
            {
              title: "Who we are",
              description:
                'SUUQ Middle East FZ LLC operates the Platform and is therefore the controller of your Personal Data (referred to as either "SUUQ", "we", "us" or "our" in this Privacy Policy). Our address is 6th Floor, Park Place, Parklands Road, Nairobi, Kenya.',
            },
            {
              title: "How to contact us",
              description: "You can contact us by emailing: privacy@SUUQ.com.",
            },
          ],
        },
        {
          title: "Personal data we collect from you",
          description:
            "The Personal Data we collect directly from you is outlined in the table below.",
          list: [
            {
              title: "Identity Data",
              description:
                "First name, last name, username or similar identifier and profile photo. In the event you decide to verify your account using our ‘SUUQ Verified’ feature, we will also process a copy of your Identification Card with all information included on it (including your Identification Card number, date of birth, nationality, and gender). We use robust measures to keep this information secure to prevent any fraudulent or malicious actions such as identity theft, phishing scams, non-delivery and payment scams.",
            },
            {
              title: "Contact Data",
              description: "Email address, telephone number(s).",
            },
            {
              title: "Location Data",
              description:
                "Approximate location if you enable this feature via your device.",
            },
            {
              title: "Listings Data",
              description:
                "Details about your previous and current listings on the Platform, as well as details of other users’ listings that you have viewed, and offers you have made for other users’ listings.",
            },
            {
              title: "Marketing Data",
              description:
                "Your preferences in receiving marketing messages from us.",
            },
            {
              title: "Chat Data",
              description:
                "Details of messages that you exchange with other users of the Platform through the ‘Chat’ feature, including any additional Personal Data you may disclose in such messages.",
            },
            {
              title: "Call Data",
              description:
                "Recordings of calls between you and our teams, which are recorded for monitoring and training purposes.",
            },
            {
              title: "Behavioural Data",
              description:
                "Inferred or assumed information relating to your behaviour and interests based on your activity on the Platform. This is most often collated and grouped into ‘segments’ on an aggregated title.",
            },
            {
              title: "Technical Data",
              description:
                "Internet protocol (IP) address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website or use our services.",
            },
            {
              description:
                "We also collect, use and share ‘aggregated data’, such as statistical or demographic data for a number of purposes. Aggregated data may be derived from your Personal Data, but once in aggregated form it will no longer constitute Personal Data as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Behavioural Data to calculate the percentage of users accessing a specific Platform feature. However, if we combine or connect aggregated data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data which will be used in accordance with this Privacy Policy.",
            },
            {
              description:
                "We do not knowingly collect any ‘special categories of personal data’ about you (this includes, for example, details about your race or ethnicity, religious or philosophical beliefs, political opinions, information about your health, genetic and/or biometric data, and information about criminal offences and convictions). We advise you not to share any of that data with us (for example, through our support chat function) or other users of the Platform (for example, through the user-to-user chat function). However, should you choose to share such data with us or other users of the Platform, you consent to us processing such data in accordance with this Privacy Policy.",
            },
          ],
        },
        {
          title:
            "What happens if you refuse to provide necessary Personal Data?",
          description:
            "You do not have to provide any Personal Data to us. However, where we need to process your Personal Data either to grant you access to the Platform or to comply with applicable law, and you fail to provide that Personal Data when requested, we may not be able to provide you access to the Platform. For example, we need your email address in order to register your account on the Platform.",
        },
        {
          title: "Personal data we collect from other titles",
          description:
            "In addition to the Personal Data that we collect directly from you (as described in the section immediately above this one), we also collect certain of your Personal Data from third party titles.",
          list: [
            {
              title: "Social media platforms",
              description: ["Identity Data", "Contact Data"],
            },
            {
              title: "Our affiliates",
              description: ["Identity Data", "Contact Data", "Marketing Data"],
            },
            {
              title: "Analytics providers",
              description: ["Behavioural Data", "Technical Data"],
            },
            {
              title: "Advertisers",
              description: ["Behavioural Data", "Technical Data"],
            },
          ],
        },
        {
          title: "How we use your personal data and why",
          description:
            "We will only use your Personal Data for the purposes for which we collected it as listed below, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your Personal Data for an unrelated purpose, we will update this Privacy Policy and we will explain the legal title which allows us to do so.",
          list: [
            {
              title: "Contractual Necessity",
              description:
                "Where we need to process your Personal Data to meet our contractual obligations to you (for example, to provide you access to the Platform).",
            },
            {
              title: "Compliance with Law",
              description:
                "Where we need to process your Personal Data to comply with our legal or regulatory obligations.",
            },
            {
              title: "Consent",
              description:
                "Where we have your consent to process your Personal Data for a specific purpose.",
            },
          ],

          uses: [
            {
              purpose: "Account creation",
              categories: ["Identity Data", "Contact Data"],
              description:
                "To register you as a user on the Platform and manage your user account.",
              legaltitle: "Contractual Necessity",
            },
            {
              purpose: "Verification",
              categories: ["Identity Data", "Contact Data"],
              description:
                "To verify your account through ‘SUUQ Verified’ if you choose to do so.",
              legaltitle: "Contractual Necessity",
            },
            {
              purpose: "Operation of our services",
              categories: [
                "Identity Data",
                "Contact Data",
                "Location Data",
                "Listings Data",
                "Chat Data",
                "Call Data",
              ],
              description:
                "To operate the Platform and enable your use of the Platform, including by allowing you to interact with other users of the Platform. To deliver other services that you have requested from us (including, for example, arranging a test drive through SUUQ Cars).",
              legaltitle: "Contractual Necessity",
            },
            {
              purpose: "Analytics",
              categories: ["Behavioural Data", "Technical Data"],
              description:
                "To understand how you and other users use the Platform and to segment our userbase into groups for marketing purposes.",
              legaltitle: "Contractual Necessity",
            },
            {
              purpose: "Marketing",
              categories: ["Contact Data", "Marketing Data"],
              description:
                "To send you marketing messages, where you have agreed to receive them.",
              legaltitle: "Consent",
            },
            {
              purpose: "Troubleshooting",
              categories: ["Technical Data"],
              description:
                "To track issues that might be occurring on our Platform and to address them.",
              legaltitle: "Contractual Necessity",
            },
            {
              purpose: "Fraud prevention",
              categories: ["Identity Data", "Contact Data"],
              description:
                "To keep our Platform and associated systems operational and secure.",
              legaltitle: "Compliance with Law",
            },
          ],
        },
        {
          title: "Who we share your personal data with",
          description:
            "The table below describes who we may share your Personal Data with and why we share it. We require all titles of your Personal Data to implement appropriate security measures to adequately protect it, consistent with our policies and any data security obligations applicable to us.",
          list: [
            {
              title: "Other users of the Platform",
              description:
                "We need to share some of your Personal Data with other users of the Platform when you wish to transact with them through the Platform. By placing an advert or submitting a review/rating on our Platform, that information and any Personal Data associated with your account profile (including your username, profile picture and SUUQ Verified status) will be publicly accessible to, and may be copied and shared externally by, all other users of our Platform. Such information may also appear in third party search engine results (for example, Google search results). Please ensure that you are comfortable with such information being publicly available before submitting it on our Platform.",
            },
            {
              title: "Service providers",
              description:
                "Our service providers provide us with a range of services which are necessary for the operation of the Platform (for example, IT, system administration services, or marketing services), and may have access to your Personal Data as a result.",
            },
            {
              title: "Our affiliates",
              description:
                "SUUQ is part of the SUUQ Group, which operates other consumer platforms and share technology with SUUQ. Our affiliated companies may require access to your Personal Data as they help us operate the Platform and manage user data.",
            },
            {
              title: "Professional advisers",
              description:
                "Our lawyers, bankers, auditors, insurers and other advisers may require limited access to your Personal Data when they provide consultancy, banking, legal, insurance and accounting services to us.",
            },
            {
              title: "Public authorities",
              description:
                "Public authorities may require us to disclose user data to them under certain circumstances, where required by law (for example, in the event of a police investigation).",
            },
            {
              title: "Acquirer(s)",
              description:
                "We may share your Personal Data with third parties in the event of any reorganisation, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, so that any potential acquirer(s) may continue operating the Platform. Where that is the case, we will ensure that any such title(s) continue to use your Personal Data in accordance with this Privacy Policy.",
            },
          ],
        },
        {
          title: "Data transfers",
          description:
            "We may transfer your Personal Data to jurisdictions outside of the country in which you are located that may not be deemed to provide the same level of data protection as your home country, as necessary for the purposes set out in this Privacy Policy. We will always ensure that any such cross-border transfers of your Personal Data comply with applicable requirements.",
        },
        {
          title: "How we keep your personal data secure",
          description:
            "We have put in place appropriate security measures to prevent your Personal Data from being accidentally lost or altered, or used or accessed in an unauthorised way. We limit access to your Personal Data to those employees and other staff who have a business need to have such access. All such persons are subject to a contractual duty of confidentiality. We have put in place procedures to deal with any actual or suspected Personal Data breach. In the event of any such breach, we have systems in place to mitigate any impact to your privacy and to work with relevant regulators.",
        },
        {
          title: "How long we store your personal data",
          description:
            "We will only retain your Personal Data for as long as we reasonably need to use it for the purposes set out in this Privacy Policy, unless a longer retention period is required by applicable law (for example, for regulatory purposes). Under some circumstances, we may anonymise your Personal Data so that it can no longer be associated with you. We may retain such anonymised data indefinitely. Our data retention policies are reviewed at regular intervals and comply with all applicable requirements.",
        },
        {
          title: "Your rights in relation to your personal data",
          description:
            "Under some circumstances, you may have certain rights in relation to your Personal Data. For example, you may have the right to:",
          list: [
            {
              description:
                "Request access to your Personal Data: This allows you to receive a copy of the Personal Data we hold about you, and to check that we are lawfully processing it.",
            },
            {
              description:
                "Request the correction of your Personal Data: This allows you to ask for any incomplete or inaccurate information we hold about you to be corrected.",
            },
            {
              description:
                "Request the erasure of your Personal Data: This allows you to ask us to delete or remove your Personal Data from our systems where there is no good reason for us to continue processing it.",
            },
            {
              description:
                "Object to the processing of your Personal Data: This allows you to object to our processing of your Personal Data for a specific purpose (for example, for marketing purposes).",
            },
            {
              description:
                "Request the transfer of your Personal Data: This allows you to request the transfer of your Personal Data in a structured, commonly-used, machine-readable format, either to you or to a third party designated by you.",
            },
            {
              description:
                "Withdraw your Consent: This right only exists where we are relying on your Consent to process your Personal Data. If you withdraw your Consent, we may not be able to provide you with access to the certain features of our Platform. We will advise you if this is the case at the time you withdraw your Consent.",
            },
            {
              description:
                "If you want to exercise any of the rights described above, please contact us. We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that your Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response. We try to respond to all legitimate requests within one month of receipt. Occasionally, it may take us longer than a month if your request is particularly complex or if you have made a number of requests. In this case, we will notify you and keep you updated. Although we will typically not charge a fee for exercising your rights described above, we reserve the right to charge a reasonable fee in some circumstances (for example, if your request is unreasonable or if you submit an excessive number of requests).",
            },
          ],
        },
        {
          title: "Complaints",
          description:
            "If you would like to make a complaint regarding this Privacy Policy or our practices in relation to your Personal Data, please contact us. We will reply to your complaint as soon as we can. If you are unsatisfied with our response to any issue that you raise with us, you may have the right to submit a complaint to the data protection authority in your jurisdiction.",
        },
        {
          title: "Marketing Communications",
          description:
            'You can ask us to stop sending you marketing messages at any time by logging in to the Platform and checking or unchecking relevant boxes to adjust your marketing preferences, or by following the "Unsubscribe" link included at the bottom of any marketing email you receive from us.',
        },
        {
          title: "Our policy on minors",
          description:
            "This Platform is not intended to be used by minors, and we do not actively monitor the age of our users. However, if you become aware that a minor has been using the Platform in breach of this restriction, please contact us if you would like us to remove their Personal Data from our systems.",
        },
        {
          title: "Third party links",
          description:
            "This Platform may include links to third party websites and applications. Clicking on those links will take you off-Platform and may allow third parties to collect or share your Personal Data. We do not control these third-party websites and applications and are not responsible for their privacy practices. When you leave our Platform, we encourage you to read the privacy policy of every website and application you visit.",
        },
        {
          title: "Changes to this privacy policy",
          description:
            "We reserve the right to update this Privacy Policy at any time, with or without notice to you. Where that is the case, we will update this page to display the revised Privacy Policy and may also under certain circumstances notify you (for example, by email). Any revisions to this Privacy Policy will be effective immediately once posted on this page.",
        },
        {
          title: "Data Privacy Notice for Candidates or Job Applications",
          description:
            "Data Privacy Notice for Candidates or Job Applications can be found here: Data Privacy Notice for Candidates or Job Applications",
        },
      ],
    },
  }),
};
</script>